<ion-header>
  <ion-toolbar>
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss(false)" mode="ios">  <fa-icon [icon]="['fal', 'close']"></fa-icon></ion-button>
    </ion-buttons>
  
  </ion-toolbar>
</ion-header>
<ion-content>

 
  <ion-card>
    <ion-card-header>
      <ion-card-title>{{title}}</ion-card-title>
    </ion-card-header>
  
    <ion-card-content>

      

      <ion-item *ngIf="leader_out">
        <ion-input placeholder="Reason" type="text" aria-label="State reason here" autocapitalize="on" (ionChange)="updateLeaderOutReason($event)"></ion-input>
      </ion-item>
      <ion-item *ngIf="account_delete">
        <ion-input type="password"  placeholder="Enter password" aria-label="Enter password" autocapitalize="on" (ionChange)="passwordEntered($event)" autocomplete="current-password"></ion-input>
      </ion-item>
<ion-item lines="none" class="ion-margin-bottom"> 
  <ion-label   innerHTML="{{statement | linky}}" ></ion-label>
</ion-item>
<div class="buttons_container">
      <ion-button *ngIf="not_confirm_btn" slot="start"  (click)="dismiss(false)">
        {{not_confirm_btn}}
      </ion-button>
      <ion-button  slot="end"  (click)="dismiss(true)">
        {{confirm_btn}}
      </ion-button>
</div>
        </ion-card-content>

  </ion-card>





</ion-content>